
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
  },
  filters: {
  },
  computed: {
  }
})
export default class IrrigationTimingPlan extends Vue {
  private tableData = []
  page = 1
  size = 10
  total = 0
  loading = true
  areaList = []
  planType = 0
  dateRange = []
  list = ['计划执行记录', '手动执行记录']
  searchForm = {
    startTime: '',
    endTime: '',
    areaId: '',
    deviceNumber: '',
    command: '',
    projectId: this.projectId
  }

  get projectId () {
    return this.$route.query.projectId
  }

  created () {
    this.getArea()
    this.loadData()
  }

  getArea () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaByPage).then((res) => {
      this.areaList = res.list || []
    })
  }

  selectType (index: number) {
    this.page = 1
    this.planType = index
    if (this.planType === 0) {
      this.loadData()
    } else {
      this.selectIrrigationValveRecordByPage()
    }
    this.searchForm.startTime = ''
    this.searchForm.endTime = ''
    this.searchForm.areaId = ''
  }

  dateChange (date: string[]) {
    if (date) {
      this.searchForm.startTime = date[0]
      this.searchForm.endTime = date[1]
    } else {
      this.searchForm.startTime = ''
      this.searchForm.endTime = ''
    }
  }

  onSearch () {
    this.page = 1
    if (this.planType === 0) {
      this.loadData()
    } else {
      this.selectIrrigationValveRecordByPage()
    }
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.irrigation.selectIrrigationPlanRecordByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 手动
  selectIrrigationValveRecordByPage () {
    this.loading = true
    this.$axios.get(this.$apis.irrigation.selectIrrigationValveRecordByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size,
      commandSource: '1'
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }
}
